@import "modules/responsive-type.scss";
$primary: #77007f;

/* MAIN COLOR */

$secondary: #ff084a;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 5px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop


// @import url('https://fonts.googleapis.com/css?family=Spectral+SC|Trade+Winds');
@import url('https://fonts.googleapis.com/css?family=Bangers|Questrial');
// font-family: 'Trade Winds', cursive;
// font-family: 'Spectral SC', serif;

html,
body {
    height: 100%;
}

html {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p,
ol,
ul {
    font-size: 1rem;
    font-family: 'Questrial', sans-serif;
}

a {
    font-size: .8rem;
    font-family: 'Bangers', cursive;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
   font-family: 'Bangers', cursive;
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
   font-family: 'Bangers', cursive;
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
    font-family: 'Bangers', cursive;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

.xdebug-error {
       display: none !important;
}

.xe-warning{
    display: none !important;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f2f2f2+100 */
    background: rgb(255, 255, 255);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    // border: 1px solid #0065dd;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 35px;
            display: flex;
            font-size: 17px;
            align-items: center;
            color: $primary;
            border-radius: $border-radius;
            transition-duration: 0.5s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                text-size:15px;
                display: inline-block;
            }
            &:focus{
                background: transparent;
                color: $primary;
            }
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $secondary;
                border-radius: 12px;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 767px) and (orientation: landscape) {
        margin: 8px 15px 8px 0px;
    }
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $blk;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {

    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        font-family: 'Questrial', sans-serif;
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 200px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 20px;
    }
}

.top-pad {
    padding: 3em 0em;
    background: white;
    @media (max-width: 990px) {
        padding: 1em 0em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    font-size: 1rem;
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

#signUpForm {
    padding: 0px 10px !important;
}

.shadowthis {
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
}

.shadowthat {
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
}


/* ========== HEADER ========== */

.header {
    // background: url('../img/banner.jpg');
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/banner.jpg);
    background-repeat: no-repeat;
   background-position: 100% 30%;
    background-size: cover;
    padding: 250px 0px;

    @media (max-width: 991px) {
        
        padding: 300px 0px;
    }
    @media (max-width: 767px) {
      
        padding: 200px 0px;
    }
    @media (max-width: 568px) {
 
        padding: 150px 0px;
    }
    @media (max-width: 414px) {
        background-position: 89% 50%;
        padding: 150px 0px;
    }
    @media (max-width: 320px) {
        background-position: 84% 50%;
        padding: 100px 0px;
    }

}

// .header-overlay {
//     background: rgba(31, 31, 31, .25);
//     padding: 250px 0px;
//     @media (max-width: 767px) {
//         padding: 50px 0px;
//     }
// }

.header h1 {
    color: $secondary;
    // text-shadow: 2px 2px $primary;
}

.header p {
    color: $wht;
}


/* ========== SECTION ONE ========== */

.section-one {
    padding: 25px 0px;
}

.section-one h1 {
    color: $primary;
}

.section-one img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 600px;
    margin-top: 50px;
    @media (max-width: 1023px) {
        margin-bottom: 25px;
    }
}

.section-one p {
    // margin-bottom: 50px;
}


/* ========== SECTION TWO ========== */

.section-two {
    // background: url('../img/lower-banner.jpg');
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/lower-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 30%;
    padding: 250px 0px;

    @media (max-width: 991px) {
        background-position: 30% 50%;
        padding: 250px 0px;
    }
    @media (max-width: 767px) {
        background-position: 30% 50%;
        padding: 200px 0px;
    }
    @media (max-width: 568px) {
        background-position: 33% 50%;
        padding: 150px 0px;
    }
    @media (max-width: 414px) {
         background-position: 55% 50%;
        padding: 150px 0px;
    }
    @media (max-width: 320px) {
        background-position: 55% 50%;
        padding: 100px 0px;
    }
}

// .section-two-overlay {
//     background: rgba(31, 31, 31, .5);
//     padding: 250px 0px;
//     @media (max-width: 767px) {
//         padding: 50px 0px;
//     }
// }

.section-two h1 {
    color: $secondary;
    // text-shadow: 2px 2px $primary;
}

.section-two p {
    color: $wht;
}


/* ========== SECTION THREE ========== */

.section-three {
    padding: 50px 0px;
}

.section-three .spacer {
    margin-top: 25px;
}

.section-three h1 {
    color: $primary;
}

.section-three img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 600px;
    @media (max-width: 1023px) {
        margin-bottom: 25px;
    }
}

.btn {
  font-family: 'Questrial', sans-serif;
  // font-family: 'Redressed', cursive;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

    &:hover {
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $secondary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}